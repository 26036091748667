// @flow

import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import Header from "../components/Header";
import useLessons from "../hooks/use-lessons";
import Layout from "../layouts/layout";

const LessonStyled = styled(Link)`
  min-height: 230px;
  border-bottom: 2px solid black;
  font-weight: bold;
  transition: all 0.2s linear;

  &.bg-highlight:hover,
  &.bg-highlight:focus {
    text-decoration: none;
    background: #fddb5d;
  }

  span {
    font-size: 100px;
  }
  strong {
    font-weight: bold;
  }
`;

function Lessons() {
  const lessons = useLessons()
    .sort((a, b) => (a.data.number > b.data.number ? 1 : -1))
    .map(lesson => (
      <li className="col-12 col-md-6" key={lesson.data.number}>
        <LessonStyled
          className="d-flex flex-column mb-4 p-3 bg-highlight"
          to={`/edukacja/${lesson.slug}`}
        >
          <span>{lesson.data.number}</span>
          <strong>{lesson.data.title}</strong>
        </LessonStyled>
      </li>
    ));

  return (
    <Layout title="Lekcje">
      <main>
        <Header>Lekcje</Header>
        <ul className="row list-unstyled">{lessons}</ul>
      </main>
    </Layout>
  );
}

export default Lessons;
